import restaurant from "./images/goldenl.jpg";

import img_card from "./images/minie5.png";
import imgheadermb from "./images/minniemb.jpg";
import imgheader from "./images/minniemb.jpg";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Ariana",
        familia: "fam. Tîmbur",
        logo: logo,
        tata: "Marcel",
        mama: "Alina",
        data: "12 Mai 2023",
        data_confirmare: "1 mai 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "alinatrinchinet76@gmail.com", 
       tel: "+373 695 30 269",
       phone: "tel:+37369530269",
       viber: "viber://chat?number=%2B37369530269",
       whatsapp: "https://wa.me/+37369530269",
       messenger: "https://www.messenger.com/t/alina01.05",
    }],
    cardData: [
   
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Golden Lion",
            name1: "(White Hall)",
            data: "12 mai 2023, vineri, ora 17:00",
            adress: "Bulevardul Moscova 17/3, Chișinău, Moldova",
            harta: "https://goo.gl/maps/rxr8TiXJnxPjXoib7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10873.067497651919!2d28.8648378!3d47.054615!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97ce3025bd715%3A0x9686bf40f12cec21!2sGolden%20Lion!5e0!3m2!1sro!2s!4v1679990613273!5m2!1sro!2s"
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "1 Anișor împlinesc,",
            title2: " și vreau să sărbătoresc!",
            message: "Cu mult drag și încântare   ",
            message1: 'Vă invităm la o petrecere tare!',
            message2: 'Cu muzică și voie bună',
            message3: 'Ne vom distra clar împreună!',
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;