import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";



import CardLocal from "../cardLocal";


import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";

import data from "../Data";
import CardInvitatie from "./CardInvitatie";
import mikey from '../images/migig.gif'
import mikey1 from '../images/mgif2.gif'

const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".8" >
                        <div id="intro" className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-5">
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '60px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>{item.copilul}</h1>
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            <div className="container text-center"  >
            <img id="mikey_img2" src={mikey1} />
              
            </div>


            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="300px" opacity=".8">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-3" style={{ maxWidth: '600px', padding: '30px'  }}>
                                <h1 style={{ fontSize: '45px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink`  }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


 {/**<BlogSectionNoi /> */}            


            <CardInvitatie/>

            <div className="container text-center" style={{ maxWidth: '600px', padding: '30px'  }} >
            <img id="mikey_img" src={mikey} />
                <h2>
A rămas foarte puțin timp până ne vedem!</h2>
<Timer />
            </div>
         <CardLocal />
    

            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                        {item.familia}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;