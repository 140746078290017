import React, { Component } from "react";

export default class Card extends Component {
    render() {
        return (
            
            <section className="card_local  py-lg-2 container">
                
            <div className="row justify-content-center mt-5">
                
                <div className="col-12 col-md-12 col-lg-4 mx-0 mb-4">
                   
                    <div className="card p-0 overflow-hidden h-100 shadow">
                        <img src={this.props.img} className="card-img-top" />
                        <div className="card-body text-center">
                          <h6 className="text-center">{this.props.title}</h6>   
                            <h5 className="card-title">{this.props.localul}</h5>
                            <h3 style={{ fontFamily: 'Dancing Script',fontSize: '40px'}} className="card-title">"{this.props.name}"</h3>
                            <h4>{this.props.name1}</h4>
                            <h5 className="card-title">{this.props.data}</h5>
                            <p className="card-text">{this.props.adress}</p>
                            <a href={this.props.harta} class="btn btn-primary">Vezi Harta</a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12 col-lg-8 mx-0 mb-4">
                    <div className="card p-0 overflow-hidden h-100 shadow">
                        <iframe className="card-img-top h-100" src={this.props.iframe} ></iframe>
                        

                    </div>
                    
                </div>
            </div>
        </section>
              
        )
    }
}

