
import React, { Component } from "react";




export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
              <h3 className="text-center">
                
              </h3>
              <h1 className="text-center ">
                {this.props.title1} <br/>
                {this.props.title2}
              </h1>
              <h5 className="text-center pb-2">
                {this.props.message} <br/>
                {this.props.message1}<br/>
                {this.props.message2}<br/>
                {this.props.message3}<br/>
              </h5>

             
            </div>
          </div>
        </div>
      </div>

    );
  }
}

